(function($){

// ---------- APP START
var App = {

  Tac: function() {

    window.tarteaucitronForceLanguage = $('html').attr('lang').substr(0, 2);
    tarteaucitron.init({
      "hashtag": "#tac",
      "highPrivacy": false,
      "orientation": "bottom",
      "adblocker": false,
      "showAlertSmall": false,
      "cookieslist": true,
      "removeCredit": true
    });

    tarteaucitron.user.gtagUa = "UA-118313383-1";
    (tarteaucitron.job = tarteaucitron.job || []).push("gtag");

    // tarteaucitron.user.googlemapsKey = "AIzaSyBhdqt1eCC5ToyAVZKghA4zjf-Q5E-8ZWc";
    // (tarteaucitron.job = tarteaucitron.job || []).push("googlemaps");
    //
    // window.gmap_callback = function() {
    //   App.initMaps();
    // };
  },

  init: function(){
    $.fn.matchHeight._maintainScroll = true;

    // Init SVG polyfill
    if(typeof(svg4everybody) !== 'undefined') svg4everybody();

    // Remove focus state when clicking on a button with a mouse
    $(':not(form) button, a').on('click', function(e){
      if(e.clientX && e.clientY)
        $(this).blur();
    });

    window.addEventListener("popstate", function(e) { window.location.reload(); });

    if($('.akam-slider-partners').length) App.Sliders.partners();
    if($('.akam-header-page__slider').length) App.Sliders.headerPage();
    if($('.akam-tab-list').length) App.TabList();
    if($('.akam-product-images-slider').length) App.Sliders.productImages();
    if($('.akam-big-slider').length) App.Sliders.bigSlider();
    if($('.akam-characteristics-list') && $(window).width() > 1023) App.Isotope.ProductCharacteristics();

    $('#scrolltop').click(function(e) {
      e.preventDefault();
      $('html,body').stop(1,1).animate({ scrollTop: 0 }, 500);
    });

    App.Leaflet.init();
  },

  load: function(){
  },

  Leaflet: {

    'infos': {
      'namedoc': '',
      'file': ''
    },

    'init': function() {
      $('.akam-leaflet-open').click(function(e) {
        e.preventDefault();
        App.Leaflet.infos.file = jQuery(this).attr('href');
        App.Leaflet.infos.namedoc = jQuery(this).data('namedoc');
        App.Leaflet.open();
      });
      $('.akam-leaflet-close').click(function(e) {
        e.preventDefault();
        App.Leaflet.close();
      });
      $('.akam-leaflet-modal form').submit(function(e) {
        e.preventDefault();
        App.Leaflet.submit($(this));
      });
      $('.akam-leaflet-modal .skip-link').click(function() {
        App.Leaflet.close();
        window.open(App.Leaflet.infos.file, '_blank');
      });
    },

    'open': function() {
      $('.akam-leaflet-backdrop').stop(1,1).fadeIn(200, function() {
        $('.akam-leaflet-modal').stop(1,1).fadeIn(200);
      });
    },

    'close': function() {
      $('.akam-leaflet-modal').stop(1,1).fadeOut(200, function() {
        $('.akam-leaflet-backdrop').stop(1,1).fadeOut(200);
      });
    },

    'submit': function($form) {
      var err_msg = $form.data('errmsg');
      var data = $form.serialize()+"&doc="+App.Leaflet.infos.namedoc;
      jQuery.post('/wordpress/wp-admin/admin-ajax.php?action=leaflet_post', data)
      //jQuery.post('https://avonmarine.local/en/efrontend/api/ressource/catalogue/post', data)
        .done(function(data) {
          if(data.type === "success") {
            App.Leaflet.close();
            window.open(App.Leaflet.infos.file, '_blank');
          } else {
            alert(err_msg);
          }
        })
        .fail(function() {
          App.Leaflet.close();
          window.open(App.Leaflet.infos.file, '_blank');
        });
    }
  },

  TabList: function() {
    $('.akam-tab-list').each(function() {
      var $this = $(this);

      if($this.hasClass('m--showing'))
        $this.children('.akam-tab-list__body').slideDown(0);

      $this.children('.akam-tab-list__title').click(function(e) {
        e.preventDefault();
        $this.toggleClass('m--showing');
        $this.children('.akam-tab-list__body').slideToggle(200);
      });
    });
  },

  Sliders: {
    partners: function() {
      $('.akam-slider-partners').slick({
        slidesToShow: 5,
        slidesToScroll: 5,
        dots: true,
        arrows: false,
        responsive: [
          {
            breakpoint: 959,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      });
    },
    headerPage: function() {
      $('.akam-header-page__slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '0px',
        dots: true,
        arrows: false
      });
    },
    productImages: function() {
      $('.akam-slider-product-image-small').slick({
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: false,
        vertical: true,
        verticalSwiping: true,
        dots: false,
        arrows: false,
        focusOnSelect: true,
        asNavFor: '.akam-slider-product-image-big'
      });
      $('.akam-slider-product-image-big').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        dots: true,
        arrows: false,
        asNavFor: '.akam-slider-product-image-small'
      });
    },
    bigSlider: function() {
      $('.akam-big-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        dots: true,
        arrows: false
      });
    }
  },

  Isotope: {
    ProductCharacteristics: function() {
      $('.m--init--showing .akam-tab-list__body').slideDown(0, function() {
        $('.akam-characteristics-list').isotope({
          percentPosition: true,
          itemSelector: '.akam-characteristics-box'
        });
        $('.m--init--showing .akam-tab-list__body').slideUp(0, function() {
          $('.akam-tab-list.m--init--showing').removeClass('m--init--showing');
        });
      });
    }
  }

};
// ---------- APP END

// Init app
$(document).ready(App.init);
// $(window).load(App.load); // jQuery 2
$(window).on('load', App.load); // jQuery 3
window.App = App;
App.Tac();
})(jQuery);

// ---------- MISSING FUNCTIONS
function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}
// ---------- MISSING FUNCTIONS END
